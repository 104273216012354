import { Button } from "reactstrap";
import { DeleteSvg, EditSvg, PluesSvg } from "../../../components/CommonSvg";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import { getValueByKey } from "../../../utils/authUtils";

export const columns = (props) => {
    return [
        {
            dataField: "name",
            text: "Vendor Contact",
            sort: true,
            formatter: (cell, row) => <span className="text-nowrap">{cell}</span>,
        },
        {
            dataField: "vendorName",
            text: "Vendor",
            sort: true,
            formatter: (cell, row) => <span className="">{cell}</span>,
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
            formatter: (cell, row) => <span className="text-nowrap">{cell}</span>,
        },
        {
            dataField: "mobile",
            text: "Mobile",
            sort: true,
            formatter: (cell, row) => <span className="text-nowrap">{cell}</span>,
        },
        {
            dataField: "phone",
            text: "Phone",
            sort: true,
            formatter: (cell, row) => <span className="text-nowrap">{cell}</span>,
        },
      
        {
            dataField: "vendorsAssignedState",
            text: "Vendor Assigned State",
            sort: true,
            formatter: (cell, row) => <span className="">{cell}</span>,
        },
        {
            dataField: "vendorTerritoryAndCities",
            text: "Vendor Territory & Cities",
            sort: true,
            formatter: (cell, row) => <span className="">{cell}</span>,
        },
        {
            dataField: "secondaryEmail",
            text: "Secondary Email",
            sort: true,
            formatter: (cell, row) => <span className="text-nowrap">{cell}</span>,
        },
        {
            dataField: "additionalEmail",
            text: "Additional Email",
            sort: true,
            formatter: (cell, row) => <span className="text-nowrap">{cell}</span>,
        },
        // {
        //     dataField: "exchangeRate",
        //     text: "Exchange Rate",
        //     sort: true,
        //     formatter: (cell, row) => <span className="px-3">{cell}</span>,
        // },
        {
            dataField: "Action",
            text: "",
            headerClasses: "action_col vendor-contacts-table",
            formatter: (cell, row) => (
                <div className="action_col text-center d-inline-flex align-items-center gap-1">
                    {JSON.parse(getValueByKey("roleId")) === "1" && (
                        <a
                            className="my-tooltip delete_btn_icon flex-shrink-0"
                            data-tooltip-content={"Delete"}
                            onClick={async (e) => {
                                if (e && e.stopPropagation) {
                                    e.stopPropagation();
                                }
                                const response = await commonConfirmBox(
                                    "Are you sure you want to remove this vendor contact?"
                                );
                                if (response) {
                                    props.deleteVendorContactRequest({
                                        delete: { deleteId: row.id },
                                        dispatch: props.dispatchData,
                                    });
                                }
                            }}
                        >
                            <DeleteSvg />
                        </a>
                    )}
                    {props.editVendorContacts && (
                        <button
                            className="my-tooltip btn btn-link edit_btn flex-shrink-0"
                            data-tooltip-content={"Edit"}
                            onClick={(e) => {
                                if (e && e.stopPropagation) {
                                    e.stopPropagation();
                                }
                                props.handleEdit(row);
                                // handleEditModalToggle(row);
                            }}
                        >
                            <EditSvg />
                        </button>
                    )}
                    <Button
                        color="info"
                        className="m-0 d-flex align-items-center gap-1 text-white flex-shrink-0"
                        title="Create Support Ticket"
                        size="sm"
                        onClick={(e) => {
                            if (e && e.stopPropagation) {
                                e.stopPropagation();
                            }
                            debugger
                            props.handleOpenSupportModal(row);
                            props.setCreateVendorTicket({
                                vendorEmail:row?.email, vendorId:row.vendorId,vendorName:row.vendorName
                            })
                        }}
                    >
                        <PluesSvg />
                        Ticket
                    </Button>
                </div>
            ),
        },
    ];
};
